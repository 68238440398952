import React, { useEffect, useState } from "react";
import logoImg from "../../assets/Logo.png";
import { Col, Form, Input, message, Radio, Row, Spin } from "antd";
import loginImage from "../../assets/LoginImg.png";
import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
import { ForgotPasswordApi, sendOtpAPi, VerifyAPi } from "../../apis/Api";
import "./ForgotPassword.scss";

const ForgotPassword = () => {
  const [selectedRole, setSelectedRole] = useState("User");
  const [loading, setLoading] = useState(false);
  const [resendOtp, setResendOtp] = useState(true);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSendOtp = async (values) => {
    const emailToSend = resendOtp ? values?.email : email;

    // Validate email before sending
    if (!emailToSend) {
      message.error("Please enter a valid email address!");
      return;
    }

    setLoading(true);
    const formData = {
      email: emailToSend,
      type: "forgot_password",
    };

    try {
      const res = await sendOtpAPi(formData);
      message.success("OTP sent to your email!");
      setEmail(emailToSend);
      setStep(2);
    } catch (e) {
      if (
        e?.response?.data?.status === 400 &&
        e?.response?.data?.message ===
          "User is not verified yet. Please verify your OTP first."
      ) {
        navigate("/verifyotp", { state: emailToSend });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    const formData = {
      email,
      otp,
      type: "forgot_password",
    };
    try {
      const res = await VerifyAPi(formData);
      message.success(res?.data?.message);
      setToken(res?.data?.data?.token);
      setStep(3);
    } catch (e) {
      console.log(e);
      message.error("Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (values) => {
    setLoading(true);
    const formData = {
      email,
      password: values.password,
      confirm_password: values?.confirmPassword,
      token,
    };
    try {
      const res = await ForgotPasswordApi(formData);
      message.success(res?.data?.message);
      navigate("/login");
    } catch (e) {
      console.log(e);
      message.error("Failed to change password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e) => {
    setSelectedRole(e.target.value);
    localStorage.setItem("myValue", e.target.value === "User" ? "6" : "7");
  };

  return (
    <div className="ForgotPassword">
      <Row className="row">
        {!isMobile && (
          <Col span={12} className="leftSide">
            <img src={loginImage} alt="login" className="loginImg" />
            <div className="imageOverlay"></div>
          </Col>
        )}
        <Col span={isMobile ? 24 : 12} className="column2">
          <div className="rightSide">
            <img src={logoImg} width={200} alt="logo" />
            <div>
              <Radio.Group
                onChange={onChange}
                value={selectedRole}
                className="Radio_btn"
              >
                <Radio value="Corporate" className="custom_radio">
                  Corporate
                </Radio>
                <Radio value="User" className="custom_radio">
                  User
                </Radio>
              </Radio.Group>
            </div>
            <h3>Forgot Password!</h3>
            {step === 1 && (
              <div className="SendEmail">
                <div className="form">
                  <Spin spinning={loading}>
                    <Form
                      name="basic"
                      initialValues={{ remember: true }}
                      autoComplete="off"
                      layout="vertical"
                      onFinish={handleSendOtp}
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your valid email!",
                            type: "email",
                          },
                        ]}
                      >
                        <Input
                          className="input_custom"
                          placeholder="example123@gmail.com"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item>
                        <CustomButton
                          htmlType="submit"
                          text="Send OTP"
                          bgColor="#3D3C6E"
                          width="100%"
                        />
                      </Form.Item>
                    </Form>
                  </Spin>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="VerifyOtp">
                <div className="form">
                  <Spin spinning={loading}>
                    <Form
                      name="basic"
                      initialValues={{ remember: true }}
                      autoComplete="off"
                      layout="vertical"
                      onFinish={handleVerifyOtp}
                    >
                      <Form.Item
                        label="OTP"
                        name="otp"
                        rules={[
                          { required: true, message: "Please input your OTP!" },
                        ]}
                      >
                        <Input
                          className="input_custom"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </Form.Item>
                      <p
                        className="resend_otp"
                        
                      >
                         Dont't get the code ? <span className="resentlink" onClick={() => {
                          if (email) {
                            handleSendOtp({ email });
                            setResendOtp(false);
                          } else {
                            message.error("Please enter your email first!");
                          }
                        }}>Resend</span> 
                      </p>
                      <Form.Item>
                        <CustomButton
                          htmlType="submit"
                          text="Verify OTP"
                          bgColor="#3D3C6E"
                          width="100%"
                        />
                      </Form.Item>
                    </Form>
                  </Spin>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="forgot-password">
                <div className="form">
                  <Spin spinning={loading}>
                    <Form
                      name="basic"
                      initialValues={{ remember: true }}
                      autoComplete="off"
                      layout="vertical"
                      onFinish={handlePasswordChange}
                    >
                      <Form.Item
                        label="Create Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                          {
                            min: 8,
                            message: "Password must be at least 8 characters long",
                          },
                          {
                            pattern:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                            message:
                              "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                          },
                        ]}
                      >
                        <Input.Password className="input_custom" />
                      </Form.Item>
                      <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("The two passwords that you entered do not match!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password className="input_custom" />
                      </Form.Item>
                      <Form.Item>
                        <CustomButton
                          htmlType="submit"
                          text="Change Password"
                          bgColor="#3D3C6E"
                          width="100%"
                        />
                      </Form.Item>
                    </Form>
                  </Spin>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
