import React from "react";
import "./ShowAttempts.scss";
import { Container } from "react-bootstrap";
import { Card, Radio, Space } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const ShowAttemptsAnswers = ({ attempt }) => {
  const totalScore = attempt?.test_marks[0]?.test_marks || 0;
  const totalQuestions = attempt?.question_count || 0;

  return (
    <Container className="ShowAttemptsAnswers">
      <h6>{`Attempt ${attempt?.test_marks[0]?.attempt} - ${attempt?.name}`}</h6>
      <h4>{`Total Score: ${totalScore}/${totalQuestions}`}</h4>
      <Card>
        {attempt?.test_questions?.map((question, index) => {
          const userAnswerId = question?.test_user_progress[0]?.user_answer_id;
          const rightAnswerId = question?.right_answer_id;

          return (
            <div key={index} className="question-block">
              <h6>{`Q${index + 1}. ${question?.question}`}</h6>
              <p>
                <strong>Correct answer:</strong> {question?.test_options.find((opt) => opt.id === rightAnswerId)?.option}
              </p>
              <p>
                <strong>Score:</strong>{" "}
                {userAnswerId === rightAnswerId ? (
                  <span style={{ color: "green" }}>
                    1 <CheckOutlined />
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    0 <CloseOutlined />
                  </span>
                )}
              </p>
              <Radio.Group value={userAnswerId}>
                <Space direction="vertical">
                  {question?.test_options?.map((option) => (
                    <Radio
                      key={option.id}
                      value={option.id}
                      style={{
                        color:
                          option.id === userAnswerId
                            ? option.id === rightAnswerId
                              ? "green" // Correct answer in green
                              : "red" // Incorrect answer in red
                            : "black", // Default color for other options
                      }}
                    >
                      {option.option}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
              <hr />
            </div>
          );
        })}
      </Card>
    </Container>
  );
};

export default ShowAttemptsAnswers;
