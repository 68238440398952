import React, { useEffect, useState } from "react";
import { Card, Rate, Input, message, Skeleton } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import CustomButton from "../CustomButton/CustomButton";
import Reviews from "../Reviews/Reviews";
import { IMAGE_URL } from "../../apis/Host";
import { useNavigate, useParams } from "react-router";
import "./CourseDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { getReviewAndRatings } from "../../redux/reviweAndRatinSlice";
import {
  GetCourseById,
  GetWithTokenCourseById,
  PostAddToCart,
  PostReviewandRatingsApi,
} from "../../apis/Api";
import { getLocalStorageItem } from "../../apis/locastorageHandle";

const { Meta } = Card;
const { TextArea } = Input;

const CourseDetails = () => {
  const [courseDetails, setCourseDetails] = useState(null);
  const [loadingCourse, setLoadingCourse] = useState(true);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isRatingProvided, setIsRatingProvided] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reviews, loading: loadingReviews, error } = useSelector(
    (state) => state.reviewRatingReducer
  );
  const userType = getLocalStorageItem("myValue");
  const token = getLocalStorageItem("userToken");

  useEffect(() => {
    setLoadingCourse(true);
    const fetchCourseDetails = async () => {
      try {
        const response = token
          ? await GetWithTokenCourseById(id)
          : await GetCourseById(id);
        if (response?.data?.success) {
          setCourseDetails(response?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching course details:", error);
      } finally {
        setLoadingCourse(false);
      }
    };
    fetchCourseDetails();
  }, [id, token]);

  useEffect(() => {
    const formData = { course_id: id };
    dispatch(getReviewAndRatings({ formData }));
  }, [id, dispatch]);

  useEffect(() => {
    if (reviews?.data) {
      setIsRatingProvided(
        reviews?.data?.some((item) => item?.user_rating_provided)
      );
    }
  }, [reviews]);

  const handlePostReview = () => {
    if (rating === 0 || reviewText.trim() === "") {
      setValidationMessage("Please provide both rating and review text.");
      return;
    }
    setValidationMessage("");
    const formData = {
      course_id: id,
      rating,
      review: reviewText,
    };
    PostReviewandRatingsApi(formData)
      .then((res) => {
        message.success(res?.data?.message);
        setRating(0);
        setReviewText("");
        dispatch(getReviewAndRatings({ formData: { course_id: id } })); // Refresh reviews
      })
      .catch((e) => {
        console.error("Error posting review:", e);
      });
  };

  const handleAddToCart = () => {
    const formData = { course_id: id };
    PostAddToCart(formData)
      .then((res) => {
        if (res?.data?.success) {
          message.success(
            res?.data?.message || "Course added to cart successfully!"
          );
          setTimeout(() => {
            navigate("/shopingcart");
          }, 2000);
        } else {
          message.error(res?.data?.message || "Failed to add course to cart.");
        }
      })
      .catch((error) => {
        console.error("Error adding to cart:", error);
      });
  };

  return (
    <div className="course-details">
      <div className="course-container">
        <div className="course-header">
          <Container className="container">
            {loadingCourse ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : (
              <>
                <div className="course-description">
                  <h2>{courseDetails?.name}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: courseDetails?.description,
                    }}
                  ></p>
                </div>
                <div className="course-image">
                  <img
                    src={`${IMAGE_URL}/${courseDetails?.course_attachments[0]?.file_name}`}
                    alt={courseDetails?.course_attachments[0]?.title}
                    crossOrigin="anonymous"
                  />
                </div>
              </>
            )}
          </Container>
        </div>

        <div className="tests-included">
          <Container className="included-container">
            <h3>Tests Included</h3>
            {loadingCourse ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
              <div className="test-cards">
                {courseDetails?.course_test?.map((test, index) => (
                  <Card
                    key={index}
                    className="test-card"
                    cover={
                      <img
                        alt="example"
                        src={`${IMAGE_URL}/${test?.test_attachments[0]?.file_name}`}
                        crossOrigin="anonymous"
                        width="250px"
                      />
                    }
                  >
                    <Meta
                      title={test?.name}
                      description={
                        <>
                          <p>
                            {test?.question_count} Questions | {test?.duration}
                          </p>
                          <CustomButton
                            text="Included"
                            bgColor="#ED1C2414"
                            color="#ED1C24"
                            width="100%"
                            padding="5px"
                          />
                        </>
                      }
                    />
                  </Card>
                ))}
                <div className="amount-card">
                  <p>Total Amount</p>
                  <div className="total-amount">
                    ${courseDetails?.price}
                  </div>
                  {courseDetails?.is_purchased === 1 ? (
                    <CustomButton
                      text="Continue Learning"
                      width="100%"
                      onClick={() =>
                        navigate(`/courseongoing/${courseDetails?.course_id}`)
                      }
                      disabled={userType === "7"}
                    />
                  ) : courseDetails?.is_in_cart === 1 ? (
                    <CustomButton
                      text="Go To Cart"
                      width="100%"
                      onClick={() => navigate("/shopingcart")}
                      disabled={userType === "7"}
                    />
                  ) : (
                    <CustomButton
                      text="Add To Cart"
                      width="100%"
                      onClick={handleAddToCart}
                      disabled={userType === "7"}
                    />
                  )}
                </div>
              </div>
            )}
          </Container>
        </div>
      </div>
      <Container>
        <Row>
          <Col xs lg="9">
            <Reviews
              reviews={reviews}
              loading={loadingReviews}
              error={error}
              course_id={id}
              setIsRatingProvided={setIsRatingProvided}
            />
            {!isRatingProvided && !loadingCourse && (
              <div className="add-review">
                <h3>Add Your Review</h3>
                <Rate
                  allowHalf
                  value={rating}
                  onChange={(value) => setRating(value)}
                  className="ratings"
                />
                <TextArea
                  rows={4}
                  className="textArea"
                  placeholder="Write Here"
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                />
                {validationMessage && (
                  <div className="validation-message">{validationMessage}</div>
                )}
                <CustomButton
                  text="Post Review"
                  bgColor="#ED1C24"
                  color="white"
                  onClick={handlePostReview}
                />
              </div>
            )}
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};

export default CourseDetails;
