import React from "react";

const CourseAbout = ({ data }) => {
  console.log(data,"data")
  return (
    <div className="About_Section">
      <h5>About Us</h5>
      <p
        dangerouslySetInnerHTML={{
          __html: data,
        }}
      ></p>
    </div>
  );
};

export default CourseAbout;
