import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Tabs, message, Skeleton } from "antd";
import VideoCard from "../VideoCard/VideoCard";
import {
  GetAllCoursesAPI,
  GetAllCoursesByToken,
  GetAllTestByToken,
  GetAllTestsAPI,
} from "../../apis/Api";
import { PostAddToCart } from "../../apis/Api";
import { useNavigate } from "react-router-dom";
import "./Courses.scss";
import { getLocalStorageItem } from "../../apis/locastorageHandle";

const Courses = () => {
  const [getAllCourses, setGetAllCourses] = useState([]);
  const [getAllTests, setGetAllTests] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [loadingTests, setLoadingTests] = useState(true);
  const [loadingItem, setLoadingItem] = useState(null);
  const token = getLocalStorageItem("userToken");
  const navigate = useNavigate();

  const formatCourseData = (data) => {
    console.log(data, "data");
    return data.map((course) => {
      // const encodedCourseId = encodeURIComponent(course.course_attachments[0]?.course_id);

      return {
        image: course.course_attachments[0]?.file_name,
        course_id: course.course_attachments[0]?.course_id, // Use encoded ID
        title: course.name,
        lectures: course.lecture_count,
        months: `${course.lecture_count} months`,
        rating: parseFloat(course.average_rating) || 0,
        price: `$${course.price}`,
        button_status:
          course.is_purchased === false
            ? course?.is_in_cart === true
              ? "Go To Cart"
              : "Add To Cart"
            : "Continue Learning",
        type: "course",
        link: !token
          ? "/login"
          : `/coursedetails/${course.course_attachments[0]?.course_id}`, // Encode the course ID in the link
        navigateLink:
          course.is_purchased === true &&
          `/courseongoing/${course.course_attachments[0]?.course_id}`,
        handleClick: handleAddToCart,
        is_purchased: course.is_purchased,
      };
    });
  };

  const formatTestData = (data) => {
    return data
      .map((test) => {
        if (test.test_attachments && test.test_attachments.length > 0) {
          const encodedTestId = encodeURIComponent(
            test.test_attachments[0]?.test_id
          );

          return {
            image: test.test_attachments[0]?.file_name,
            test_id: encodedTestId, // Use encoded ID
            title: test.name,
            questions: test.question_count,
            minutes: test.duration,
            rating: parseFloat(test.average_rating) || 0,
            price: `$${test.price}`,
            button_status:
              test.is_purchased === false
                ? test?.is_in_cart === true
                  ? "Go To Cart"
                  : "Add To Cart"
                : "Start Test",

            type: "test",
            navigateLink: !token
              ? "/login"
              : test.is_purchased === false
              ? "/shopingcart"
              : `/quiz/${encodedTestId}`,
            handleClick: handleAddToCart,
            is_purchased: test.is_purchased,
          };
        }
        return null;
      })
      .filter((test) => test !== null);
  };

  const fetchCourses = () => {
    setLoadingCourses(true);
    const fetchAPI = token ? GetAllCoursesByToken : GetAllCoursesAPI;
    fetchAPI()
      .then((res) => {
        const data = res?.data?.data;
        if (Array.isArray(data)) {
          const formattedData = formatCourseData(data);
          setGetAllCourses(formattedData);
        }
      })
      .catch((e) => {
        console.log("Error fetching courses:", e);
      })
      .finally(() => setLoadingCourses(false));
  };
  useEffect(() => {
    fetchCourses();
  }, [token]);

  useEffect(() => {
    const fetchTests = () => {
      setLoadingTests(true);
      const fetchAPI = token ? GetAllTestByToken : GetAllTestsAPI;
      fetchAPI()
        .then((res) => {
          const data = res?.data?.data;
          if (Array.isArray(data)) {
            const formattedData = formatTestData(data);
            setGetAllTests(formattedData);
          }
        })
        .catch((e) => {
          console.log("Error fetching tests:", e);
        })
        .finally(() => setLoadingTests(false));
    };

    fetchTests();
  }, [token]);

  const handleAddToCart = (id, type, navigateLink, button_status) => {
    // console.log(button_status, "button_status");
    setLoadingItem(id);
    if (button_status === "Go To Cart") {
      navigate("/shopingcart");
    } else {
      let formData;

      if (type === "course") {
        formData = { course_id: id };
      } else if (type === "test") {
        formData = { test_id: id };
      }

      PostAddToCart(formData)
        .then((res) => {
          if (res.data.success) {
            message.success(res.data.message);
            setTimeout(() => navigate(navigateLink), 2000);
          }
          fetchCourses()
        })
        .catch((e) => {
          console.log("Error adding to cart:", e);
        })
        .finally(() => setLoadingItem(null));
    }
  };

  // const items = [
  //   {
  //     key: "1",
  //     label: "Courses",
  //     children: (
  //       <VideoCard
  //         cardData={getAllCourses}
  //         loading={loadingCourses}
  //         loadingItem={loadingItem}
  //         type="shopcourse"
  //       />
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: "Tests",
  //     children: (
  //       <VideoCard
  //         cardData={getAllTests}
  //         loading={loadingTests}
  //         loadingItem={loadingItem}
  //       />
  //     ),
  //   },
  // ];

  return (
    <Container>
      <div className="course">
        {/* <h3>Shop</h3> */}
        {/* <Tabs defaultActiveKey="1" items={items} /> */}

        <VideoCard
          cardData={getAllCourses}
          loading={loadingCourses}
          loadingItem={loadingItem}
          type="shopcourse"
        />
      </div>
    </Container>
  );
};

export default Courses;
