import React, { useState } from "react";
import { Card, Button, Skeleton, Progress, Modal } from "antd";
import { IMAGE_URL } from "../../apis/Host";
import { useNavigate } from "react-router-dom";
import nodataavailable from "../../assets/nodataavailable.jpg";
import "./VideoCard.scss";
import { getLocalStorageItem } from "../../apis/locastorageHandle";

const VideoCard = ({ cardData, loading, loadingItem, disableTestButton, type }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const userType = getLocalStorageItem("myValue");

  const handleAttemptClick = (item) => {
    if (item.attempt === "0/3") {
      setIsModalVisible(true);
    } else if (type !== "shopcourse") {
      navigate(`/show-attempt/${item?.test_id}`);
    }
  };

  const handleStartTest = () => {
    setIsModalVisible(false);
    navigate("/starttest"); // Redirect to the start test page
  };

  const handleModalClose = () => {
    setIsModalVisible(false); // Close the modal
  };

  if (loading) {
    return (
      <div className="cards">
        {[...Array(6)].map((_, index) => (
          <Skeleton active key={index} className="card-skeleton" />
        ))}
      </div>
    );
  }

  if (!Array.isArray(cardData) || cardData.length === 0) {
    return (
      <div className="no-data">
        <img src={nodataavailable} alt="No data available" />
        <p>No data available</p>
      </div>
    );
  }
const handleRedirection=(button_status,link)=>{
  if(button_status==="Start Test"){
    navigate(link)
  }

}
console.log(cardData,"item")
console.log(cardData,"cardData")
  return (
    <div className="cards">
      {cardData.map((item, index) => (
        <div key={index}>
          <Card
            hoverable
            className="card"
            cover={
              <img
               
                src={`${IMAGE_URL}/${item.image}`}
                alt="video"
                crossOrigin="anonymous"
              />
            }
          >
            <div className="textlink">
              <h6 className="title"  onClick={() =>
                  (type === "course" || type === "shopcourse") &&
                  navigate(`${item?.link}`)
                }>{item.title}</h6>
              <div>
                {item.type === "test" ? (
                  <div>
                    <span>{item.questions} Questions | </span>
                    <span>{item.minutes}</span>
                  </div>
                ) : (
                  <>
                    <div className="details">
                      <span>{item.lectures} Lectures</span>
                      <span>⭐ {item.rating}</span>
                    </div>
                    {item.dashboard && (
                      <div className="courseProgress">
                        <Progress
                          percent={item.progress}
                          showInfo={false}
                          strokeColor={
                            item.progress === 100 ? "green" : "orange"
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              {item.price && <h6 className="price">{item.price}</h6>}
              {item.attempt && (
                <h6
                  className="price"
                  onClick={() => handleAttemptClick(item)}
                  style={{ textDecoration: "underline", color: "blue" }}
                >
                  Attempts: {item.attempt}
                </h6>
              )}
            </div>
            <Button
              type="primary"
              className="button"
              loading={
                item.button_status === "Add To Cart" &&
                loadingItem === (item.course_id || item.test_id)
              }
              onClick={() => {
                if (item.is_purchased !== undefined && item.is_purchased) {
                  navigate(item.navigateLink);
                } else {
                  item.handleClick(
                    item.course_id || item.test_id,
                    item.type,
                    item.navigateLink,
                    item.button_status
                  );
                }
                // item.button_status==="Start Test"?
                // navigate(`${item.link}`):navigate("/hello")
            //  navigate(`${item?.navigateLink}`)
              }
            }
              disabled={item.attempt === "3/3"}
            >
              {item.button_status}
            </Button>
          </Card>
        </div>
      ))}

      {/* Modal Implementation */}
      <Modal
        title="No Attempts Made"
        visible={isModalVisible}
        onOk={handleStartTest} // Start test button
        onCancel={handleModalClose} // Close button
        okText="Start Test"
        cancelText="Close"
      >
        <p>You have not attempted this test yet. Please start your test.</p>
      </Modal>
    </div>
  );
};

export default VideoCard;
