import { Card, Carousel, message, Modal, Button, Rate, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { FaEdit, FaStar } from "react-icons/fa";
import {
  DeleteReviewandRating,
  EditReviewandRating,
  PostReviewandRatingsApi,
} from "../../apis/Api";
import { useDispatch, useSelector } from "react-redux";
import { getReviewAndRatings } from "../../redux/reviweAndRatinSlice";
import { useParams } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdDeleteForever } from "react-icons/md";

const CourseReview = () => {
  const { id } = useParams(); // Course ID from route
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentReview, setCurrentReview] = useState(null);
  const [newRating, setNewRating] = useState(0);
  const [newReview, setNewReview] = useState("");

  const { reviews, loading, error } = useSelector(
    (state) => state.reviewRatingReducer
  );

  useEffect(() => {
    const formData = { course_id: id };
    dispatch(getReviewAndRatings({ formData }));
  }, [id, dispatch]);

  const showModal = (review = null) => {
    if (review && review.id) {
      setCurrentReview(review);
      setNewRating(review.rating);
      setNewReview(review.review);
    } else {
      setCurrentReview(null);
      setNewRating(0);
      setNewReview("");
    }
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (!currentReview) return;

    const formData = {
      review: newReview,
      rating: newRating,
      course_id: id,
    };

    try {
      const response = await EditReviewandRating(formData);
      if (response.data.success) {
        message.success(response.data.message);
        dispatch(getReviewAndRatings({ formData: { course_id: id } }));
      }
    } catch (error) {
      console.error(error);
    }

    setIsModalVisible(false);
  };

  const handleDelete = async (reviewId) => {
    try {
      const response = await DeleteReviewandRating(reviewId);
      if (response.data.success) {
        message.success(response.data.message);
        dispatch(getReviewAndRatings({ formData: { course_id: id } }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlePostReview = () => {
    if (newRating === 0 || newReview.trim() === "") {
      message.error("Please provide both rating and review text.");
      return;
    }

    const formData = {
      course_id: id,
      rating: newRating,
      review: newReview,
    };

    PostReviewandRatingsApi(formData)
      .then((res) => {
        message.success(res?.data?.message);
        setNewRating(0);
        setNewReview("");
        dispatch(getReviewAndRatings({ formData: { course_id: id } }));
      })
      .catch((e) => {
        console.error("Error posting review:", e);
      });
  };

  return (
    <div className="Reviews_Section">
      <div className="Review_heading">
        <h5>Reviews</h5>
        <div className="Write_Review_btn" onClick={() => showModal({})}>
          Write Review
        </div>
      </div>

      {loading && (
        <Skeleton
          active
          paragraph={{ rows: 4 }}
          title={false}
          className="review_skeleton"
        />
      )}

      {error && <p>Error loading reviews: {error.message}</p>}

      {!loading && reviews?.data?.length > 0 && (
        <Carousel
          afterChange={(current) => console.log("Current slide:", current)}
          slidesToShow={2}
          slidesToScroll={1}
          responsive={[
            { breakpoint: 1024, settings: { slidesToShow: 2 } },
            { breakpoint: 600, settings: { slidesToShow: 1 } },
          ]}
        >
          {reviews.data.map((item, index) => (
            <div key={index}>
              <Card className="review_card">
                <div className="review_box">
                  <div className="review_box_inner">
                    <div className="review_icon_section">
                      <div className="review-icon">
                        {item.review_course?.user_profile?.name
                          ?.charAt(0)
                          .toUpperCase()}
                      </div>
                    </div>
                    <div>
                      <div className="review-name">
                        <span className="name">
                          {item.review_course?.user_profile?.name}
                        </span>
                        <span className="rating">
                          <FaStar />
                        </span>
                        <span className="value">{item.rating}</span>
                      </div>
                      <div className="review-date">
                        {new Date(item.created_at).toLocaleDateString()}
                      </div>
                      <p className="review_text">{item.review}</p>
                    </div>
                  </div>
                  {item?.user_rating_provided === true && (
                    <div className="review-actions">
                      <div
                        className="icon_edit"
                        onClick={() => showModal(item)}
                        style={{ marginRight: 8 }}
                      >
                        <FaEdit color="blue" />
                      </div>
                      <div
                        className="icon_delete"
                        onClick={() => handleDelete(item.id)}
                      >
                        <MdDeleteForever color="red" />
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
      )}

      {!loading && !error && reviews?.data?.length === 0 && (
        <p>No reviews available. Be the first to write one!</p>
      )}

      <Modal
        title={currentReview?.id ? "Edit Review" : "Write Review"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <label>Rating:</label>
          <Rate value={newRating} onChange={(value) => setNewRating(value)} />
        </div>
        <div>
          <label>Review:</label>
          <textarea
            value={newReview}
            onChange={(e) => setNewReview(e.target.value)}
            rows={4}
            style={{ width: "100%" }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CourseReview;
