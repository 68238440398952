import React from "react";
import { Modal, Card, Button } from "antd";
import CustomButton from "../CustomButton/CustomButton";
import "./ResultCard.scss";

const ResultCard = ({
  isVisible,
  onClose,
  restartQuiz,
  handleContinue,
  quizResults,
  
}) => {
  return (
    <Modal
      title="Your Quiz Results"
      visible={isVisible}
      onCancel={onClose}
      maskClosable={false} // Disable closing by clicking outside
      keyboard={false}
      style={{ width: "700px" }}
      footer={[
        <div className="quizResultContainer" key="footer">
          {/* <CustomButton
            key="restart"
            text="Restart Quiz"
            onClick={restartQuiz}
          />
          <span style={{ margin: "0 8px" }} /> */}
          <CustomButton key="done" text="Done" onClick={onClose}  />
        </div>,
      ]}
    >
      <div className="ResultCard">
        {/* Ant Design Card for Score */}
        <Card
          className="card-score"
          bordered={false}
          style={{ marginBottom: "16px" }}
        >
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <h3 className="score__result">
              Your Score Is:
              <span className="card-score__result__count">
                &nbsp;{quizResults[0]?.["User Score"] || 0}
              </span>
              <span className="card-score__result__max">
                /{quizResults[0]?.["Total Score"] || 100}
              </span>
            </h3>
            {/* <Button>View Answer</Button> */}
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default ResultCard;
