import React, { useEffect, useState } from "react";
import { Input, Button, Avatar, Space, message } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import CustomButton from "../CustomButton/CustomButton";
import { GetFAQAPI, PostFAQApi } from "../../apis/Api";
import { Container } from "react-bootstrap";
import "./Forum.scss";

const Forum = () => {
  const [question, setQuestion] = useState("");
  const [faqs, setFaqs] = useState([]);

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };

  const handlePostFaq = () => {
    if (question.trim()) {
      const formData={
        question:question
      }
      PostFAQApi(formData)
        .then((res) => {
          message.success("Question posted successfully!");
          setQuestion("");
          loadFaqs();
        })
        .catch((e) => {
          console.log("error", e);
          message.error("Failed to post the question.");
        });
    } else {
      message.warning("Please enter a question before submitting.");
    }
  };

  const loadFaqs = () => {
    GetFAQAPI()
      .then((res) => {
        console.log(res,"res")
        setFaqs(res?.data?.data);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  useEffect(() => {
    loadFaqs();
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, []);

  return (
    <Container>
      <div className="forum-container">
        <div className="forum-content">
          <h1>Forum</h1>
          <div
            style={{
              border: "1px solid #e8e8e8",
              padding: "24px",
              marginBottom: "20px",
            }}
          >
            <div className="question-form">
              {/* <Avatar
                src="https://via.placeholder.com/40"
                className="question-avatar"
              /> */}
              <Input
                placeholder="Type your question here..."
                className="question-input"
                value={question}
                onChange={handleInputChange}
              />
            </div>
            <CustomButton
              text="Ask Question"
              width="130px"
              padding="5px"
              margin="10px 0px"
              onClick={handlePostFaq}
              disabled={!question.trim()}
            />
          </div>
          {faqs.map((item) => (
            <div key={item.id} className="forum-item">
              <div className="forum-item-meta">
                <Avatar className="forum-item-avatar">
                  {item?.forum_user?.user_profile.name.charAt(0).toUpperCase()}
                </Avatar>
                <div className="forum-item-info">
                  <h2>{item?.forum_user?.user_profile.name}</h2>
                  <p>{item.question}</p>
                  <div className="forum-item-content">{item.answer}</div>
                  <Space className="forum-item-actions">
                    {/* <span>
                      <MessageOutlined />{" "}
                      {item.comments ? item.comments.length : 0} Comments
                    </span> */}
                    <span>
                      {new Date(item.created_at).toLocaleDateString()}
                    </span>
                  </Space>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Forum;
