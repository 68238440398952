import { List } from "antd";
import React from "react";

const CourseCurriculm = ({ data }) => {
  console.log(data);
  //   const data = [
  //     "Racing car sprays burning fuel into crowd.",
  //     "Japanese princess to wed commoner.",
  //     "Australian walks 100km after outback crash.",
  //     "Man charged over missing wedding girl.",
  //     "Los Angeles battles huge wildfires.",
  //   ];
  return (
    <div className="Course_Curriculm_Section">
      <h5>Course Curriculm</h5>
      <List
        size="small"
        bordered
        dataSource={data}
        renderItem={(item) => <List.Item>{item?.name}</List.Item>}
      />
    </div>
  );
};

export default CourseCurriculm;
