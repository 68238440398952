// // apiClient.js
// import axios from "axios";
// import { message } from "antd";
// import { getLocalStorageItem } from "./locastorageHandle";
// const Base_URL = `https://node.nucleargauges.com:5000/api/v1/`;
// const apiClient = axios.create({
//   baseURL: Base_URL,
// });
// apiClient.interceptors.request.use(
//   (config) => {
//     console.log(config, "testttconfig==>>");
//     const token = getLocalStorageItem("userToken");
//     let role_id;
//     console.log(role_id, "rolelll1");
//     if (!config.role_id) {
//       role_id = getLocalStorageItem("myValue");
//     console.log(role_id, "rolelll2");

//       if (!role_id) {
//         role_id = 6;
//       }
//     }
//     if (token) {
//       config.headers["x-access-token"] = token;
//     }
//     console.log(role_id, "rolelll3");

//     config.headers["role_id"] = role_id;

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// apiClient.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response) {
//       const statusCode = error.response.status;
//       const statusMsg = error.response.data.message;
//       if ((statusCode === 500 || statusCode === 404) && statusMsg) {
//         message.error("Session expired. Please log in again.");
//         // localStorage.removeItem("userToken");
//         // localStorage.removeItem("myValue");
//         setTimeout(() => {
//           // Redirect to login page if needed
//           // window.location.href = "/login";
//         }, 1000);
//       } else if (statusCode === 401 && statusMsg === "Please authenticate") {
//         message.error("Your session has expired. Please log in again.");
//         // localStorage.removeItem("userToken");
//         // localStorage.removeItem("myValue");
//         setTimeout(() => {
//           // Redirect to login page if needed
//           // window.location.href = "/login";
//         }, 1000);
//       } else if (statusCode === 400) {
//         message.error(statusMsg);
//       }
//     } else {
//       message.error(
//         "An unexpected error occurred. Please check your connection."
//       );
//     }
//     return Promise.reject(error);
//   }
// );
// export default apiClient;

// apiClient.js
import axios from "axios";
import { message } from "antd";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "./locastorageHandle";

const Base_URL = `https://node.nucleargauges.com:5000/api/v1/`;

const apiClient = axios.create({
  baseURL: Base_URL,
});

// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    console.log(config, "config");
    const token = getLocalStorageItem("userToken");

    let role_id =
      config.headers?.role_id || getLocalStorageItem("myValue") || 6;

    if (token) {
      config.headers["x-access-token"] = token;
    }

    config.headers["role_id"] = role_id;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const token = getLocalStorageItem("userToken");

    if (error.response) {
      const statusCode = error.response.status;
      const statusMsg = error.response.data.message;

      if (statusCode === 404) {
        message.error("Session expired. Please log in again.");

        message.error(statusMsg);
        removeLocalStorageItem("userToken");
        removeLocalStorageItem("myValue");
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      } else if (statusCode === 401 && statusMsg === "Please authenticate") {
        if (!token) {
          window.location.href = "/login";
        } else {
          removeLocalStorageItem("userToken");
          removeLocalStorageItem("myValue");
          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        }
      } else if (statusCode === 400||statusCode===403) {
        message.error(statusMsg);
      } else if (statusCode === 500) {
        removeLocalStorageItem("userToken");
        removeLocalStorageItem("myValue");
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      }
    } else {
      message.error(
        "An unexpected error occurred. Please check your connection."
      );
    }
    return Promise.reject(error);
  }
);

// Exporting API for Login
export const LoginAPI = async (formData, headers) => {
  return await apiClient.post(`user/auth/login`, formData, { headers });
};

export default apiClient;
