import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, message, Spin } from "antd";
import { useNavigate } from "react-router";
import { MdOutlineEmail } from "react-icons/md";
import { SlLockOpen } from "react-icons/sl";
import { FaUserAlt } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import SignUpImg from "../../assets/SignupImg.png";
import CustomButton from "../CustomButton/CustomButton";
import Logo from "../../assets/Logo.png";
import { SignUpAPI } from "../../apis/Api";
import "./SignUp.scss";
import parsePhoneNumberFromString from "libphonenumber-js";
const SignUp = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const onFinish = (values) => {
    setLoading(true); // Start spinner
    const headers = {
      role_id: 6,
    };
    const formData = {
      name: values.fullname,
      email: values.email,
      mobile: phoneNumber,
      password: values.password,
      confirm_password: values.confirmPassword,
    };
    SignUpAPI(formData, headers)
      .then((res) => {
        setLoading(false); // Stop spinner
        if (res.data.success) {
          message.success(res.data.message);
          navigate("/verifyotp", { state: formData.email });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false); // Stop spinner on error
        console.log("error", e);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };
  const validatePhoneNumber = (rule, value) => {
    if (!value) {
      return Promise.reject("Phone number is required.");
    }
    const phoneNumber = parsePhoneNumberFromString(value);
    if (phoneNumber && phoneNumber.isValid()) {
      return Promise.resolve();
    }
    return Promise.reject("Invalid phone number.");
  };
  return (
    <div className="signup">
      <Row className="row">
        {!isMobile && (
          <Col span={12}>
            <div className="leftSide">
              <img src={SignUpImg} alt="login" className="loginImg" />
            </div>
          </Col>
        )}
        <Col span={isMobile ? 24 : 12} className="column2">
          <div className="rightSide">
            <img
              src={Logo}
              width={200}
              alt="logo"
              onClick={() => navigate("/")}
            />
            <h1 className="heading">Sign Up</h1>
            <p className="para">Enter your details to get started</p>
            <div className="form">
              <Spin spinning={loading}>
                {" "}
                {/* Wrap the form with Spin */}
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    label="Full Name"
                    name="fullname"
                    rules={[
                      {
                        required: true,
                        message: "Please input your full name!",
                      },
                    ]}
                  >
                    <Input
                      className="input_custom"
                      placeholder="Full Name"
                      prefix={<FaUserAlt size={18} />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your vaild email!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input
                      className="input_custom"
                      placeholder="Email"
                      prefix={<MdOutlineEmail />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mobile"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        validator: validatePhoneNumber,
                      },
                    ]}
                    >
                    <PhoneInput
                    className="Custom-phone-input-style"
                      placeholder="Enter Mobile Number"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      defaultCountry="US"
                      countries={["US", "CA"]}
                      inputClass="custom-phone-input"
                     
                    />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="input_custom"
                      placeholder="Password"
                      prefix={<SlLockOpen />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="input_custom"
                      placeholder="Confirm Password"
                      prefix={<SlLockOpen />}
                    />
                  </Form.Item>
                  <CustomButton
                    htmlType="submit"
                    text="Sign Up"
                    bgColor="#3D3C6E"
                    width="100%"
                  />
                </Form>
              </Spin>{" "}
              {/* End of Spin wrapping */}
            </div>
            <div className="bottom_text">
              Already have an account?{" "}
              <span
                onClick={() => navigate("/login")}
                style={{ cursor: "pointer", color: "#3D3C6E" }}
              >
                Login
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default SignUp;
