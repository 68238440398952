import React, { useEffect, useState } from "react";
import { Tabs, Skeleton } from "antd";
import { Container } from "react-bootstrap";
import VideoCard from "../VideoCard/VideoCard";
import { DashBoardItems } from "../../apis/Api";
import { useNavigate } from "react-router";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import "./Dashboard.scss";
const Dashboard = () => {
  const [courses, setCourses] = useState([]);
  const [tests, setTests] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [loadingTests, setLoadingTests] = useState(true);
  const navigate = useNavigate();
  const onChange = (key) => {};
  // const items = [
  //   {
  //     key: "1",
  //     label: "Courses",
  //     children: loadingCourses ? (
  //       <div className="loader">
  //         <Skeleton active paragraph={{ rows: 4 }} />
  //       </div>
  //     ) : courses.length > 0 ? (
  //       <VideoCard cardData={courses} type="course" />
  //     ) : (
  //       <NoDataAvailable message="No courses available" />
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: "Tests",
  //     children: loadingTests ? (
  //       <Skeleton active paragraph={{ rows: 4 }} />
  //     ) : tests.length > 0 ? (
  //       <VideoCard cardData={tests} disableTestButton={true} type="test" />
  //     ) : (
  //       <NoDataAvailable message="No tests available" />
  //     ),
  //   },
  //   {
  //     key: "3",
  //     label: "Certificate",
  //     children: "Certificates...",
  //   },
  // ];
  useEffect(() => {
    const fetchCourses = () => {
      const type = "course";
      const formdata = {
        type: type,
      };
      DashBoardItems(formdata)
        .then((res) => {
          console.log(res, "res==>>");
          if (res.data.success) {
            const fetchedCourses = res.data.data.data
              .filter((item) => item.dashboard_course)
              .map((item) => ({
                image: item.dashboard_course?.course_attachments[0]?.file_name,
                title: item.dashboard_course.name,
                rating: item.dashboard_course.average_rating,
                button_status: "Continue Learning",
                type: type,
                course_id: item.dashboard_course.id,
                progress: item.progress,
                link: `/coursedetails/${item?.course_id}`, // Encode the course ID in the link
                navigateLink: `/courseongoing/${item.dashboard_course.id}`,
                handleClick: handleNavigate,
                dashboard: "dashboard",
              }));
            setCourses(fetchedCourses);
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setLoadingCourses(false);
        });
    };
    const fetchTests = () => {
      const type = "test";
      const formdata = {
        type: type,
      };
      DashBoardItems(formdata)
        .then((res) => {
          if (res.data.success) {
            const fetchedTests = res?.data?.data?.data
              .filter((item) => item?.dashboard_test)
              .map((item) => ({
                image: item.dashboard_test?.test_attachments[0]?.file_name,
                title: item.dashboard_test.name,
                questions: item.dashboard_test.question_count,
                minutes: item.dashboard_test.duration,
                rating: 0,
                button_status: "Start Test",
                type: type,
                attempt: item?.dashboard_test?.attempts,
                test_id: item.dashboard_test.id,
                navigateLink: `/quiz/${item.dashboard_test.id}`,
                // link: `/quiz/${item.dashboard_test.id}`,
                handleClick: handleNavigate,
                // navigateLink
              }));
            setTests(fetchedTests);
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setLoadingTests(false);
        });
    };
    fetchCourses();
    fetchTests();
  }, []);
  const handleNavigate = (id, type, navigateLink) => {
    navigate(navigateLink);
  };
  return (
    <Container>
      <div className="dashboard-course">
        <h3>My Learning</h3>
        {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
        {courses.length > 0 ? (
          <VideoCard cardData={courses} type="course" />
        ) : (
          <NoDataAvailable message="No courses available" />
        )}
      </div>
    </Container>
  );
};
export default Dashboard;
